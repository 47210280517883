window.addEventListener('load', ()=>{
    faq();
})

const body = document.querySelector('body');

function faq() {
    const faqs = body.querySelectorAll('.accordeon-wrapper .acordeon-item');

    faqs.forEach(faq => {
        const button = faq.querySelector('.acordeon-label');     
        
        button.addEventListener('click', ()=>{
            faq.classList.toggle('open');
        });
    });
}